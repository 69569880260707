import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import s from './Catalog.module.scss';
import p1 from './img/p1.jpg';
import p2 from './img/p2.jpg';
import p3 from './img/p3.jpg';
import p4 from './img/p4.jpg';
import p5 from './img/p5.jpg';

const Catalog = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>ЛАОЛА | КАТАЛОГ</title>
        <meta
          name="description"
          content="Колекції дверей на будь-який смак. Продаж, проектування, встановлення. Найкраща якість та доступні ціни!"
        />
      </Helmet>
      <div className={s.catalog}>
        <NavLink to="/catalog/pa" className={s.cardBox}>
          <div className={s.cardList}>
            <h5 className={s.listTitle}>
              Колекції під фарбування та приховані короба
            </h5>
            <div className={s.serias}>
              <NavLink to="/catalog/pa" className={s.more}>
                Серія PA
              </NavLink>
              <NavLink to="/catalog/pe" className={s.more}>
                Серія PE
              </NavLink>
              <NavLink to="/catalog/m" className={s.more}>
                Серія M
              </NavLink>
            </div>
          </div>

          <img src={p1} alt="p1" className={s.imgCatalog} />
        </NavLink>
        <NavLink to="/catalog/pw" className={s.cardBox}>
          <div className={s.cardList}>
            <h5 className={s.listTitle}>Колекції фарбованих дверей</h5>
            <div className={s.serias}>
              <NavLink to="/catalog/pw" className={s.more}>
                Серія PW
              </NavLink>
              <NavLink to="/catalog/pm" className={s.more}>
                Серія PM
              </NavLink>
              <NavLink to="/catalog/p" className={s.more}>
                Серія P
              </NavLink>
              <NavLink to="/catalog/u" className={s.more}>
                Серія U
              </NavLink>
            </div>
          </div>

          <img src={p2} alt="kolFarb" className={s.imgCatalog} />
        </NavLink>
        <NavLink to="/catalog/ax" className={`${s.cardBox} ${s.cardListBig}`}>
          <div className={s.cardList}>
            <h5 className={s.listTitle}>
              Колекції скляних міжкімнатних дверей в алюмінієвому профілі
            </h5>
            <div className={s.serias}>
              <NavLink to="/catalog/ax" className={s.more}>
                Серія AX
              </NavLink>
              <NavLink to="/catalog/av" className={s.more}>
                Серія AV
              </NavLink>
              <NavLink to="/catalog/ag" className={s.more}>
                Серія AG
              </NavLink>
              <NavLink to="/catalog/agn" className={s.more}>
                Серія AGN
              </NavLink>
              <NavLink to="/catalog/agp" className={s.more}>
                Серія AGP
              </NavLink>
              <NavLink to="/catalog/agk" className={s.more}>
                Серія AGK
              </NavLink>
            </div>
          </div>
          <div className={` ${s.imgCatalogS}`}>
            <img
              src={p3}
              alt="kolFarb"
              className={`${s.imgCatalogBig} ${s.imgCatalog}  `}
            />
          </div>
        </NavLink>
          <NavLink to="/catalog/n" className={s.cardBox}>
          <div className={s.cardList}>
            <h5 className={s.listTitle}>Колекції з дерев’яним покриттям</h5>
            <div className={s.serias}>
              <NavLink to="/catalog/n" className={s.more}>
                Серія N
              </NavLink>
              <NavLink to="/catalog/na" className={s.more}>
                Серія NA
              </NavLink>
            </div>
          </div>

          <img src={p4} alt="kolFarb" className={s.imgCatalog} />
        </NavLink>
        <NavLink to="/peregorodki" className={s.cardBox}>
          <div className={s.cardList}>
            <h5 className={s.listTitle}>Розсувні алюмінієві перегородки</h5>
            <div className={s.serias}>
              <NavLink to="/peregorodki" className={s.more}>
                Каскад
              </NavLink>
              <NavLink to="/peregorodki" className={s.more}>
                Класичне відкриття
              </NavLink>
            </div>
          </div>

          <img src={p5} alt="kolFarb" className={s.imgCatalog} />
        </NavLink>
      </div>
    </>
  );
};

export default Catalog;
